import React from 'react';

const Spinner = () => {
    return (
        <div className="loadingio-spinner-double-ring-238aqtlwgn3"><div className="ldio-rfwki38ms6">
            <div></div>
            <div></div>
            <div><div></div></div>
            <div><div></div></div>
        </div></div>
    );
}

export default Spinner;
